import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ph-radio',
  templateUrl: './ph-radio.component.html',
  styleUrls: ['./ph-radio.component.scss']
})
export class PhRadioComponent implements OnInit {

  @Input("checked") checked: boolean
  @Input("form") form: FormGroup
  @Input("control") control: string
  @Input("name") name: string
  @Input("label") label: string = ""
  @Input("value") value: string = ""
  @Input("disabled") disabled: string = undefined

  id: string = Math.floor(new Date().valueOf() * Math.random()).toString()

  constructor() { }

  ngOnInit() {
  }

}
