import { ValErrorsEnum, InputStatusEnum } from '../../../utils/enums';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { Labels } from 'src/utils/labels';

@Component({
  selector: 'ph-input',
  templateUrl: './ph-input.component.html',
  styleUrls: ['./ph-input.component.scss']
})
export class PhInputComponent implements OnInit {

  @Input("value") value: string = ""
  @Input("form") form: FormGroup
  @Input("control") control: string
  @Input("type") type: string = "text"
  @Input("label") label: string = ""
  @Input("placeholder") placeholder: string = ""
  @Input("disabled") disabled: string = undefined

  error: boolean = false
  errorMessage: string = ""
  toggle: boolean = true

  constructor(private ref: ElementRef) { }

  ngOnInit() {
  }

  ngAfterViewInit() {

    let that = this
    let wrapper = this.ref.nativeElement.querySelector(".clr-input-wrapper")

    if (this.form) {

      this.form.controls[this.control].statusChanges.subscribe({

        next: (status) => {

          if (status == InputStatusEnum.VALID) {

            that.error = false
            that.errorMessage = ""
            wrapper.classList.remove("clr-error")

          } else {

            that.error = true
            that.errorMessage = that.decodeErrors()
            wrapper.classList.add("clr-error")

          }

        },
        error: () => {

        },
        complete: () => {

        }

      })
    }

  }

  togglePassword() {

    this.toggle = !this.toggle

    this.ref.nativeElement.querySelector("input").setAttribute("type", this.toggle ? "password" : "text")

  }

  private decodeErrors(): string {

    let errors = this.form.controls[this.control].errors

    for (let key in errors) {

      if (key == ValErrorsEnum.REQUIRED && errors[key]) {
        return Labels.ERROR.VALIDATION.REQUIRED
      }

    }

    return Labels.ERROR.VALIDATION.UNKNOWN

  }

}