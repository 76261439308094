import { Component, OnInit, Input } from '@angular/core';
import { ITimeline } from 'src/interfaces/ITimeline';

@Component({
  selector: 'ph-timeline',
  templateUrl: './ph-timeline.component.html',
  styleUrls: ['./ph-timeline.component.scss']
})
export class PhTimelineComponent implements OnInit {

  @Input("timeline") timeline: ITimeline[] = new Array()

  constructor() { }

  ngOnInit() {
  }

}
