import { IResponse, IResponseError } from "../interfaces/IResponse";
import { StatusEnum } from "../utils/enums";
import { ErrorService } from "../services/error/error.service";

export class ResponseReader {

    private errorService: ErrorService

    /**
     * Costruttore
     * @param response Payload della Response
     * @param error Payload dell'errore
     */
    constructor(public response: IResponse, public error: IResponseError) {

        this.errorService = new ErrorService()

    }

    /**
     * Recupera il payload della Response
     * @param reduce Se true, ritorna il primo elemento dell'array
     */
    public getData<T>(reduce: boolean = false): T {

        if (reduce && this.response.data) {

            return this.response.data.length ? this.response.data[0] : this.response.data

        } else {

            return this.response.data

        }
    }

    /**
     * Recupera il numero di record totali
     */
    public getRows(): number {

        return this.response.totalRows

    }

    /**
     * Recupera il codice di Errore
     */
    public getErrorCode(): number {

        if (this.response) {

            // Se l'errore è applicativo:
            // Recupera il codice di errore all'interno della proprietà Report

            return this.response.report.code

        } else
        if (this.error) {

            // Se l'errore è HTTP:
            // Recupera il codice di errore all'interno della proprietà Status

            return this.error.status

        } else {

            // Errore sconosciuto

            return 0

        }

    }

    /**
     * Recupera il messaggio di Errore
     */
    public getErrorMessage(): string {

        if (this.response) {

            // Se l'errore è applicativo:
            // Recupera il messaggio di errore all'interno della proprietà Report

            return this.response.report.message

        } else
        if (this.error) {

            // Se l'errore è HTTP:
            // Recupera il message di errore all'interno della proprietà Status

            return this.error.statusText

        } else {

            // Errore sconosciuto

            return "unknown error"

        }
    }

    /**
     * Decodifica Messaggio d'Errore
     */
    public decode(): string {

        return this.errorService.decode(this.getErrorCode())

    }

    /**
     * Recupera Status risposta
     */
    public getStatus(): StatusEnum {

        return this.response.status

    }

    /**
     * Controlla se la risposta è un Success
     */
    public isSuccess(): boolean {

        return this.response && this.response.status == StatusEnum.SUCCESS

    }

    /**
     * Controlla se la risposta è un Warning
     */
    public isWarning(): boolean {

        return this.response && this.response.status == StatusEnum.WARNING

    }

    /**
     * Controlla se la risposta è un Error
     */
    public isError(): boolean {

        return !!this.error || this.response.status == StatusEnum.ERROR

    }

}