import { Component, OnInit } from '@angular/core';
import { Races } from '../../../classes/races';
import { Classes } from '../../../classes/classes';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  constructor() { }

  // Variabili per statistiche varie del PG

  pgLevel: number;
  proficiency: number;

  // Variabili per le abilità

  str: number;
  dex: number;
  con: number;
  int: number;
  sag: number;
  car: number;

  // Variabili per i modificatori di abilità

  modStr: number;
  modDex: number;
  modCon: number;
  modInt: number;
  modSag: number;
  modCar: number;
  modTxc: number;

  // Variabili per i tiri per colpire

  txcStr: boolean;
  txcDex: boolean;
  txcInt: boolean;
  txcSag: boolean;
  txcCar: boolean;

  // Array di razze e classi
  races: Races[];
  classes: Classes[];

  ngOnInit() {
    this.pgLevel = 1;
    this.proficiency = 2;
    this.str = 8;
    this.dex = 8;
    this.con = 8;
    this.int = 8;
    this.sag = 8;
    this.car = 8;
    this.updateStr();
    this.updateDex();
    this.updateCon();
    this.updateInt();
    this.updateSag();
    this.updateCar();
    this.modTxc = 0;
    this.txcStr = false;
    this.txcDex = false;
    this.txcSag = false;
    this.txcInt = false;
    this.txcCar = false;
    this.races = [{id: 1, nome: 'Umano', stats: '11111'},
                  {id: 2, nome: 'Elfo', stats: '11111'},
                  {id: 3, nome: 'Goblin', stats: '11111'},
                  {id: 4, nome: 'Orco', stats: '11111'},
                  {id: 5, nome: 'Halfling', stats: '11111'}
                ];
    this.classes = [{id: 1, nome: 'Guerriero', stats: '11111'},
                    {id: 2, nome: 'Ladro', stats: '11111'},
                    {id: 3, nome: 'Mago', stats: '11111'},
                    {id: 4, nome: 'Chierico', stats: '11111'},
                    {id: 5, nome: 'Barbaro', stats: '11111'}
                ];
  }

updateProf() {
    this.proficiency = 2 + Math.floor( (this.pgLevel - 1) / 4);
  }

updateToggle(who: number) {
    switch (who) {
      case 1: {
        if (this.txcStr) {
          this.txcDex = false;
          this.txcInt = false;
          this.txcSag = false;
          this.txcCar = false;
          this.modTxc = this.modStr;
        } else {
          this.modTxc = 0;
        }
      }       break;
      case 2: {
        if (this.txcDex) {
          this.txcStr = false;
          this.txcInt = false;
          this.txcSag = false;
          this.txcCar = false;
          this.modTxc = this.modDex;
        } else {
          this.modTxc = 0;
        }
      }       break;
      case 3: {
        if (this.txcInt) {
          this.txcDex = false;
          this.txcStr = false;
          this.txcSag = false;
          this.txcCar = false;
          this.modTxc = this.modInt;
        } else {
          this.modTxc = 0;
        }
      }       break;
      case 4: {
        if (this.txcSag) {
          this.txcDex = false;
          this.txcInt = false;
          this.txcStr = false;
          this.txcCar = false;
          this.modTxc = this.modSag;
        } else {
          this.modTxc = 0;
        }
      }       break;
      case 5: {
        if (this.txcCar) {
          this.txcDex = false;
          this.txcInt = false;
          this.txcStr = false;
          this.txcSag = false;
          this.modTxc = this.modCar;
        } else {
          this.modTxc = 0;
        }
      }
    }
  }

updateStr() {
    if (this.str < 8) {
      this.str = 8;
    }
    if (this.str > 20) {
      this.str = 20;
    }
    this.modStr = this.check_stat(this.str);
    if ( this.txcStr ) {
      this.modTxc = this.modStr;
    }
  }

updateDex() {
    if (this.dex < 8) {
      this.dex = 8;
    }
    if (this.dex > 20) {
      this.dex = 20;
    }
    this.modDex = this.check_stat(this.dex);
    if ( this.txcDex ) {
      this.modTxc = this.modDex;
    }
  }

updateCon() {
    if (this.con < 8) {
      this.con = 8;
    }
    if (this.con > 20) {
      this.con = 20;
    }
    this.modCon = this.check_stat(this.con);
  }

updateInt() {
    if (this.int < 8) {
      this.int = 8;
    }
    if (this.int > 20) {
      this.int = 20;
    }
    this.modInt = this.check_stat(this.int);
    if ( this.txcInt ) {
      this.modTxc = this.modInt;
    }
  }

updateSag() {
    if (this.sag < 8) {
      this.sag = 8;
    }
    if (this.sag > 20) {
      this.sag = 20;
    }
    this.modSag = this.check_stat(this.sag);
    if ( this.txcSag ) {
      this.modTxc = this.modSag;
    }
  }

updateCar() {
    if (this.car < 8) {
      this.car = 8;
    }
    if (this.car > 20) {
      this.car = 20;
    }
    this.modCar = this.check_stat(this.car);
    if ( this.txcCar ) {
      this.modTxc = this.modCar;
    }
  }

check_stat(stat: number) {
    if (stat < 10) {
      return -1;
    } else if (stat < 12) {
      return 0;
    } else if (stat < 14) {
      return 1;
    } else if (stat < 16) {
      return 2;
    } else if (stat < 18) {
      return 3;
    } else if (stat < 20) {
      return 4;
    } else {
      return 5;
    }
  }

}
