import { Component, OnInit, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'ph-spinner',
  templateUrl: './ph-spinner.component.html',
  styleUrls: ['./ph-spinner.component.scss']
})
export class PhSpinnerComponent implements OnInit {

  baseClass: string = "lds-dual-ring-"

  @Input("size") size: string = "md"
  @Input("color") color: string = "primary"

  constructor(private _element: ElementRef) { }

  ngOnInit() {

    // Applica classi allo spinner

    let element = this._element.nativeElement.querySelector(".spinner-wrapper>div")

    element.classList.add(this.baseClass + this.size)
    element.classList.add(this.baseClass + this.color)

  }

}
