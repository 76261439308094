import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
  }

  onYes() {
    console.log('L utente ha cliccato CONFERMA su Logout');
    // Effettuo logout
    this.auth.logOut();
    this.router.navigate(['login']);
  }

  onNo() {
    // console.log('L utente ha cliccato ANNULLA');
    // Eventuali operazioni da fare in caso di annulla
  }
}
