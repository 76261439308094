import { IConfirm } from 'src/interfaces/IConfirm';
import { SharedService } from './../shared/shared.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  constructor(private shared: SharedService) { }

  /**
   * Mostra Modale
   * @param title Titolo del Modale
   * @param text Corpo del Modale
   */
  showModal(title: string, text: string) {

    // Oggetto Modal da inserire nello State Tree
    // Impostato come aperto

    let modal: IConfirm = {
      title: title,
      text: text,
      open: true,
      output: null
    }

    // Aggiorna Modale nello State Tree

    this.shared.updateStateTree("confirmationModal", modal)

  }

  /**
   * Chiusura del Modale
   * @param output Esito del click dell'utente
   */
  closeModal(output: boolean) {

    // Oggetto Modal da inserire nello State Tree
    // Impostato come chiuso

    let modal: IConfirm = {
      title: "",
      text: "",
      open: false,
      output: output
    }

    // Aggiorna Modale nello State Tree

    this.shared.updateStateTree("confirmationModal", modal)

  }

  /**
   * Distruggi il Modale nello State Tree
   */
  deleteModal() {

    // Distruggi il Modale

    this.shared.clearProperty("confirmationModal")

  }
}