import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DatepickerOptions } from 'ng2-datepicker';
import * as itLocale from 'date-fns/locale/it';

@Component({
  selector: 'ph-datepicker',
  templateUrl: './ph-datepicker.component.html',
  styleUrls: ['./ph-datepicker.component.scss']
})
export class PhDatepickerComponent implements OnInit {

  @Input("value") value: string = ""
  @Input("form") form: FormGroup
  @Input("control") control: string
  @Input("label") label: string = ""
  @Input("placeholder") placeholder: string = ""
  @Input("disabled") disabled: string = undefined

  options: DatepickerOptions

  constructor(private ref: ElementRef) {
  }

  ngOnInit() {

    this.options = {
      minYear: 1970,
      maxYear: 2099,
      displayFormat: 'DD/MM/YYYY',
      barTitleFormat: 'DD/MM/YYYY',
      dayNamesFormat: 'ddd',
      firstCalendarDay: 1, // 0 - Sunday, 1 - Monday
      locale: itLocale, // Language
      //minDate: new Date(Date.now()), // Minimal selectable date
      //maxDate: new Date(Date.now()),  // Maximal selectable date
      barTitleIfEmpty: this.placeholder,
      placeholder: this.placeholder, // HTML input placeholder attribute (default: '')
      addClass: 'clr-input', // Optional, value to pass on to [ngClass] on the input field
      addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
      //fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
      useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
    }

  }

  ngAfterViewInit() {

    // let input = this.ref.nativeElement.querySelector(".clr-input")

  }

}
