import { ConfirmationService } from './../../../services/confirmation/confirmation.service';
import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/services/shared/shared.service';
import { IConfirm } from 'src/interfaces/IConfirm';

@Component({
  selector: 'ph-modal-confirm',
  templateUrl: './ph-modal-confirm.component.html',
  styleUrls: ['./ph-modal-confirm.component.scss']
})
export class PhModalConfirmComponent implements OnInit {

  open: boolean = false
  title: string = "Conferma Operazione"
  text: string = "Sei sicuro di voler confermare questa operazione?"

  constructor(private shared: SharedService, private confirmationService: ConfirmationService) { }

  ngOnInit() {

    var that = this

    // Sottoscrivi allo State Tree

    this.shared.currentStateTree.subscribe({

      next: (tree) => {

        // Leggi Modale aggiornato nello State Tree

        let modal = tree.read<IConfirm>("confirmationModal")

        // Imposta valori del Modale

        if (modal) {

          that.title = modal.title
          that.text = modal.text
          that.open = modal.open

        }
      
      },
      error: (error) => {

        console.log("Confirmation Modal error", error)

      }
    })

  }

  onClose(output: boolean) {

    // Chiudi Modale

    this.confirmationService.closeModal(output)

  }

}
