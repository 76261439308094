import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ph-input-tooltip',
  templateUrl: './ph-input-tooltip.component.html',
  styleUrls: ['./ph-input-tooltip.component.scss']
})
export class PhInputTooltipComponent implements OnInit {

  @Input("icon") icon: string
  @Input("content") content: string
  @Input("position") position: string = "top"
  @Input("size") size: string = "md"
  
  constructor() { }

  ngOnInit() {
  }

}
