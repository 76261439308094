import { Pipe, PipeTransform } from '@angular/core';
import { Labels } from './../utils/labels';

@Pipe({
  name: 'label'
})
export class LabelPipe implements PipeTransform {

  /**
   * Pipe di trasformazione label
   * @param value Valore da trasformare
   */
  transform(value: string): string {

    // Dereferenziazione dell'oggetto tramite stringa
    // Se l'oggetto è null, ritorna il valore della stringa

    return this.deref(Labels, value) || value

  }

  /**
   * Dereferenziazione oggetto
   * @param obj Oggetto da dereferenziare
   * @param s Valore in stringa del percorso
   */
  deref(obj, s) {

    let i = 0
    s = s.split('.')

    while (obj && i < s.length)
      obj = obj[s[i++]]
    
    return obj
    
  }
}
