import { SharedService } from './../../services/shared/shared.service';
import { ConfirmationService } from './../../services/confirmation/confirmation.service';
import { Directive, Output, EventEmitter, ElementRef, Input } from '@angular/core';
import { IConfirm } from 'src/interfaces/IConfirm';

@Directive({
  selector: '[AreYouSure]'
})
export class ConfirmationDirective {

  @Input("modalTitle") modalTitle: string = "Conferma Operazione"
  @Input("modalText") modalText: string = "Sei sicuro di voler confermare questa operazione?"

  @Output() yes = new EventEmitter()
  @Output() no = new EventEmitter()

  constructor(
    private ref: ElementRef,
    private confirmationService: ConfirmationService,
    private shared: SharedService
  ) { }

  ngOnInit() {

    var that = this

    // Evento Click dell'elemento
    
    this.ref.nativeElement.onclick = () => {

      // Mostra Modale di conferma passando Titolo e Testo

      that.confirmationService.showModal(that.modalTitle, that.modalText)

    }

    // Sottoscrizione Modale nello State Tree

    this.shared.currentStateTree.subscribe({

      next: (tree) => {

        // Leggi Modale aggiornato

        let modal = tree.read<IConfirm>("confirmationModal")

        if (modal && modal.output != null && modal.output) {

          // Se il Modale era aperto, ed è stato premuto OK, emetti YES

          that.yes.emit()

          // Distruggi il Modale

          that.confirmationService.deleteModal()

        } else if (modal && modal.output != null && !modal.output) {

          // Se il Modale era aperto, ed è stato premuto NO, emetti NO

          that.no.emit()

          // Distruggi il Modale

          that.confirmationService.deleteModal()

        }

      },
      error: (error) => {

        console.log("Confirmation Modal error", error)

      }
    })
  }
}