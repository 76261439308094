import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ph-tabs',
  templateUrl: './ph-tabs.component.html',
  styleUrls: ['./ph-tabs.component.scss'],
})
export class PhTabsComponent implements OnInit {

  @Input("type") type: string = ""

  constructor() { }

  ngOnInit() {
  }

}
