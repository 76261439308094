import { Labels } from './../../utils/labels';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  /**
   * Costruttore
   */
  constructor() {
  }

  /**
   * Decodifica codice d'errore
   * @param code Codice d'errore
   */
  decode(code: number): string {

    let message: string

    switch (code) {

      // ERRORI APPLICATIVI

      case 300:
      case 301:
        message = Labels.ERROR.APPLICATION.GENERIC_ERROR
        break

      case 310:
        message = Labels.ERROR.APPLICATION.BAD_LOGIN
        break

      case 311:
        message = Labels.ERROR.APPLICATION.SESSION_EXPIRED
        break

      case 312:
        message = Labels.ERROR.APPLICATION.INVALID_TOKEN
        break

      case 313:
        message = Labels.ERROR.APPLICATION.UNAUTHORIZED
        break

      // ERRORI HTTP

      case 0:
        message = Labels.ERROR.HTTP.TIMEOUT_NO_CONNECTION
        break

      case 400:
        message = Labels.ERROR.HTTP.BAD_REQUEST
        break

      case 404:
        message = Labels.ERROR.HTTP.NOT_FOUND
        break

      case 405:
        message = Labels.ERROR.HTTP.NOT_ALLOWED
        break

      // ERRORI SCONOSCIUTI

      default:
        message = Labels.ERROR.UNKNOWN

    }

    return message

  }
}