import { AlertService } from 'src/services/alert/alert.service';
import { Component, OnInit } from '@angular/core';
import { ITimeline } from 'src/interfaces/ITimeline';

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.scss']
})
export class ShowcaseComponent implements OnInit {

  solidLoading: boolean = false
  outlineLoading: boolean = false
  flatLoading: boolean = false
  solidIconLoading: boolean = false
  outlineIconLoading: boolean = false
  flatIconLoading: boolean = false

  people: any[] = [
    { id: 1, name: "Mario Rossi" },
    { id: 2, name: "Giuseppe Verdi" },
    { id: 3, name: "Sara Bianchi" },
    { id: 4, name: "Marco Neri" },
    { id: 5, name: "Paola Bruni" }
  ]

  timeline: ITimeline[] = [
    { footer: "Verifica crediti", description: "100%", type: "success" },
    { footer: "Perizia CTU", description: "100%", type: "success" },
    { footer: "Asta", description: "90%", type: "loading" },
    { footer: "Aste successive", description: "50%", type: "current" },
    { footer: "Saldo", description: "0%", type: "error" },
    { footer: "Riparto", description: "0%", type: "next" }
  ]

  constructor(private alert: AlertService) { }

  ngOnInit() { }

  onYes() {
    console.log("L'utente ha cliccato CONFERMA")
  }

  onNo() {
    console.log("L'utente ha cliccato ANNULLA")
  }

  delTab(e) {

    e.stopPropagation()

    console.log(e.srcElement.parentElement)

    if (e.srcElement.parentElement.classList.contains("active")) {

      e.srcElement.parentElement.parentElement.previousElementSibling.querySelector("button").click()

    }
    
    e.srcElement.parentElement.parentElement.remove()
  }

  onAlert(type: string) {

    let text = ""

    switch (type) {

      case "success":
        text = "L'operazione è andata a buon fine"
        break

      case "info":
        text = "Questo è un messaggio informativo"
        break

      case "warning":
        text = "Questo è messaggio di avviso"
        break

      case "danger":
        text = "Messaggio di segnalazione di un errore"
        break

    }

    this.alert.showAlert(text, type, 8)
  }

  onSolidClick() {

    this.solidLoading = true

    setTimeout(() => this.solidLoading = false, 2000)
  }

  onOutlineClick() {

    this.outlineLoading = true

    setTimeout(() => this.outlineLoading = false, 2000)
  }

  onFlatClick() {

    this.flatLoading = true

    setTimeout(() => this.flatLoading = false, 2000)
  }

  onSolidIconClick() {

    this.solidIconLoading = true

    setTimeout(() => this.solidIconLoading = false, 2000)
  }

  onOutlineIconClick() {

    this.outlineIconLoading = true

    setTimeout(() => this.outlineIconLoading = false, 2000)
  }

  onFlatIconClick() {

    this.flatIconLoading = true

    setTimeout(() => this.flatIconLoading = false, 2000)
  }
}