import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ph-tab',
  templateUrl: './ph-tab.component.html',
  styleUrls: ['./ph-tab.component.scss']
})
export class PhTabComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
