import { ResponseReader } from './../../../classes/response-reader';
import { AuthService } from './../../../services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IAuth } from 'src/interfaces/IAuth';
import 'rxjs/add/operator/finally';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginErrorMessage: string = '';
  loginLoading: boolean = false;

  form = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  })

  constructor(private router: Router, private auth: AuthService) { }

  ngOnInit() {

    if (this.isLogged()) {

      this.logIn('admin', 'admin');

    }

  }

  /**
   * Quando il form viene inviato
   */
  onSubmit() {

    let that = this;

    // Resetta messaggio d'errore e avvia animazione di caricamento

    this.loginErrorMessage = '';
    this.loginLoading = true;

    // Leggi dati del form

    let username = this.form.controls.username.value;
    let password = this.form.controls.password.value;

    // Mock timeout

    setTimeout(() => {

      that.logIn(username, password);

    }, 2000);
  }

  /**
   * Effettua il Login
   * @param username username login
   * @param password password login
   */
  logIn(username: string, password: string) {

    let that = this;

    // Invio chiamata HTTP di Login

    this.auth
      .logIn(username, password)
      .finally(() => {
        that.loginLoading = false;
      })
      .subscribe({

        next: (reader: ResponseReader) => {

          // Leggi la risposta

          let response = reader.getData<IAuth>();

          // Aggiungi dati utente alla sessione

          that.writeAuthData(response)

          // Naviga l'utente sulla Home

          that.router.navigate(['/dashboard']);
        },
        error: (error: ResponseReader) => {

          // Stampa messaggio d'errore

          that.loginErrorMessage = error.decode();

          // Rimuovi dati utente dalla sessione

          that.clearAuthData();

        }
      });
  }

  /**
   * Scrivi dati utente sulla sessione
   * @param authData dati utente
   */
  private writeAuthData(authData: IAuth) {

    localStorage.setItem('tk-short', authData.tokenshort);
    localStorage.setItem('tk-long', authData.tokenlong);
    localStorage.setItem('role', authData.role);

  }

  /**
   * Rimuovi dati utente dalla sessione
   */
  private clearAuthData() {

    localStorage.removeItem('tk-short');
    localStorage.removeItem('tk-long');
    localStorage.removeItem('role');

  }

  /**
   * Verifica se l'utente è loggato e restituisce booleano
   */
  isLogged(): boolean {

    return this.auth.isLogged();

  }
}