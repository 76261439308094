import { BtnColorEnum } from './../../../utils/enums';
import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';

@Component({
  selector: 'ph-button',
  templateUrl: './ph-button.component.html',
  styleUrls: ['./ph-button.component.scss']
})
export class PhButtonComponent implements OnInit {

  @Input("shape") shape: string = "solid"
  @Input("loading") loading: boolean = false
  @Input("disabled") disabled: string = undefined
  @Input("icon") icon: string = undefined
  @Input("iconside") iconside: string = "left"
  @Input("size") size: string = undefined
  @Input("submit") type: string
  @Input("text") text: string
  @Input("color") color: string

  side: boolean = true
  isIcon: boolean = false
  btnTouched: boolean = false
  btnLoading: ClrLoadingState = ClrLoadingState.DEFAULT

  constructor(private ref: ElementRef) { }

  ngOnInit() {

    this.isIcon = this.icon && !this.text
    this.side = this.iconside == "left"

  }

  ngOnChanges() {

    if (this.loading) {

      this.btnTouched = true
      this.btnLoading = ClrLoadingState.LOADING

    } else if (this.btnTouched) {

      this.btnLoading = ClrLoadingState.SUCCESS

    } else {

      this.btnLoading = ClrLoadingState.DEFAULT

    }
  }

  ngAfterViewInit() {

    let btn = this.ref.nativeElement.querySelector("button")

    switch (this.color) {

      case "success":
        btn.classList.add(BtnColorEnum.SUCCESS)
        break

      case "info":
        btn.classList.add(BtnColorEnum.INFO)
        break

      case "warning":
        btn.classList.add(BtnColorEnum.WARNING)
        break

      case "danger":
        btn.classList.add(BtnColorEnum.DANGER)
        break
        
      default:
        break

    }
  }
}