import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ph-checkbox',
  templateUrl: './ph-checkbox.component.html',
  styleUrls: ['./ph-checkbox.component.scss']
})
export class PhCheckboxComponent implements OnInit {

  @Input("type") type: string = "checkbox"
  @Input("checked") checked: boolean
  @Input("form") form: FormGroup
  @Input("control") control: string
  @Input("label") label: string = ""
  @Input("placeholder") placeholder: string = ""
  @Input("disabled") disabled: string = undefined

  id: string = Math.floor(new Date().valueOf() * Math.random()).toString()

  constructor() { }

  ngOnInit() {
  }

}