import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ph-select',
  templateUrl: './ph-select.component.html',
  styleUrls: ['./ph-select.component.scss']
})
export class PhSelectComponent implements OnInit {

  @Input("label") label: string = ""
  @Input("form") form: FormGroup
  @Input("control") control: string
  @Input("placeholder") placeholder: string = ""
  @Input("disabled") disabled: string = undefined

  @Input("items") items: any[] = new Array()
  @Input("itemValue") itemValue: string
  @Input("itemLabel") itemLabel: string

  constructor() { }

  ngOnInit() {
  }

}
