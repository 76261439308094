import { IAlert } from '../../interfaces/IAlert';
import { SharedService } from 'src/services/shared/shared.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private alertTimer: any
  private alert: IAlert = null

  constructor(private shared: SharedService) { }

  showAlert(text: string, type: string, duration: number = 5) {

    // Distruggi eventuali Timer e Alert precedenti

    this.destroyAlert()

    // Crea nuovo oggetto Alert

    this.alert = {
      text: text,
      type: type
    }

    // Inserisci l'Alert nello State Tree

    this.shared.updateStateTree("alert", this.alert)

    // Imposta un nuovo Timer per rimuovere l'Alert dallo State Tree

    this.alertTimer = setTimeout(() => this.destroyAlert(), duration*1000)

  }

  destroyAlert() {

    // Distruggi Timer dell'Alert

    if (this.alertTimer) window.clearTimeout(this.alertTimer)

    // Rimuovi l'Alert dallo State Tree

    this.shared.clearProperty("alert")

  }
}
