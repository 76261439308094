import { AuthService } from './../services/auth/auth.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import 'hammerjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private router: Router, private auth: AuthService) {
  }

  /**
   * Verifica se l'utente è loggato e restituisce booleano
   */
  isLogged(): boolean {

    return this.auth.isLogged()

  }
}