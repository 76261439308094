import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from '../classes/auth-guard';
import { ShowcaseComponent } from './pages/showcase/showcase.component';
import { StatsComponent } from './pages/stats/stats.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'showcase',
    component: ShowcaseComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'stats',
    component: StatsComponent,
    canActivate: [AuthGuard]
  },
  // {
  //     path: 'gestione/notifiche',
  //     component: PatientNotificationComponent,
  //     canActivate: [AuthGuard],
  //     data: {
  //         requiresPatient: true,
  //         requiresDoctor: true
  //     }
  // },
  {
    path: '**',
    redirectTo: 'login'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
