import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpSenderService } from '../http/http-sender.service';
import { Observable } from 'rxjs';
import { ResponseReader } from 'src/classes/response-reader';
import { RequestBuilder } from 'src/classes/request-builder';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends HttpSenderService {

  /**
   * Costruttore
   * @param http Servizio richieste HTTP
   */
  constructor(http: HttpClient) {
    super(http, 'auth');
  }

  /**
   * Login
   * @param username Username
   * @param password Password
   */
  logIn(username: string, password: string): Observable<ResponseReader> {

    // Costruisci oggetto LogIn

    const data = {
      username,
      password
    };

    // Login MOCK

    if (username === 'admin' && password === 'admin') {

      return super.doMock('assets/mock/loginOK.json');

    } else {

      return super.doMock('assets/mock/loginKO.json');

    }
  }

  logOut() {
    if (this.isLogged()) {
      localStorage.clear();
    }
  }

  /**
   * Verifica se l'utente è loggato
   */
  isLogged(): boolean {

    const tokenShort = localStorage.getItem('tk-short');
    const tokenLong  = localStorage.getItem('tk-long');

    // Ritorna true se entrambi i token sono presenti nella sessione

    return !!tokenShort && !!tokenLong;

  }
}
