import { IStateTreeProperty } from './shared.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  // Dichiarazione privata BehaviorSubject

  private stateTree: BehaviorSubject<StateTree>

  // Dichiarazione pubblica Observable

  public currentStateTree: Observable<StateTree>

  /**
   * Costruttore
   */
  constructor() {

    // Istanzia nuovo State Tree osservabile

    this.stateTree = new BehaviorSubject(new StateTree())

    // Valorizza Observable pubblico

    this.currentStateTree = this.stateTree.asObservable()

  }

  /**
   * Aggiorna lo State Tree
   * @param prop Nome della proprietà che si vuole aggiornare nello State Tree
   * @param value Valore della proprietà che si vuole aggiornare nello State Tree
   * @param persistence Salva la proprietà nello Storage del browser (default: false)
   */
  public updateStateTree(prop: string, value: any, persistence: boolean = false) {

    // Recupera l'ultimo State Tree

    let stateTree = this.stateTree.getValue()

    // Scrivi nuovo valore nella proprietà specificata

    stateTree.write(prop, value, persistence)

    // Rendi la modifica osservabile

    this.stateTree.next(stateTree)

  }

   /**
   * Aggiorna lo State Tree massivamente
   * @param props Array di proprietà dello State Tree per l'aggiornamento di più proprietà insieme
   */
  public updateStateTreeBulk(props: IStateTreeProperty[]) {

    // Recupera l'ultimo State Tree

    let stateTree = this.stateTree.getValue()

    // Scrivi le nuove proprietà nello State Tree

    props.forEach(prop => {

      stateTree.write(prop.prop, prop.value, prop.persistence || false)
      
    })

    // Rendi la modifica osservabile

    this.stateTree.next(stateTree)

  }

  /**
   * Rimuovi lo State Tree dallo Storage e dalla memoria
   */
  public clearStateTree() {

    // Rimuovi lo State Tree dallo Storage

    localStorage.removeItem("state-tree")

    // Resetta lo State Tree in memoria

    this.stateTree.next(new StateTree())

  }

  /**
   * Rimuovi persistenza di una proprietà dallo State Tree
   * @param prop Nome della proprietà che si vuole rimuovere dalla persistenza
   */
  public clearPropertyPersistence(prop: string) {

    // Recupera lo State Tree dallo Storage

    let tree = JSON.parse(localStorage.getItem("state-tree")) || {}

    // Rimuovi la proprietà specificata e reinserisci lo State Tree nello Storage

    delete tree[prop]

    localStorage.setItem("state-tree", JSON.stringify(tree))

  }

  /**
   * Rimuovi proprietà dallo State Tree
   * @param prop Nome della proprietà che si vuole rimuovere dallo State Tree
   */
  public clearProperty(prop: string) {

    // Rimuovi la persistenza della proprietà

    this.clearPropertyPersistence(prop)

    // Rimuovi la proprietà dallo State Tree

    this.updateStateTree(prop, null)

  }
}

export class StateTree {

  // Oggetto interno dello State Tree

  private tree: any = {}

  // Costruttore State Tree

  constructor() {

    if (localStorage.getItem("state-tree")) {

      // Se esiste uno State Tree nel Local Storage, inizializzare con quel valore

      this.tree = JSON.parse(localStorage.getItem("state-tree"))

    }
  }

  /**
   * Leggi proprietà specificata nello State Tree
   * @param prop Nome della proprietà che si vuole leggere nello State Tree
   */
  public read<T>(prop: string) {

    return this.tree[prop] as T

  }

  /**
   * Scrivi in una proprietà dello State Tree
   * @param prop Nome della proprietà nella quale si vuole scrivere
   * @param value Valore della proprietà che si vuole scrivere
   * @param persistence Salva la proprietà nello Storage del browser
   */
  public write(prop: string, value: any, persistence: boolean) {

    // Scrivi nello State Tree

    this.tree[prop] = value

    if (persistence) {

      // Se la persistenza è true:
      // Recupera lo State Tree dallo Storage

      let tree = JSON.parse(localStorage.getItem("state-tree")) || {}

      // Aggiorna la proprietà specificata e reinserisci lo State Tree nello Storage

      tree[prop] = value

      localStorage.setItem("state-tree", JSON.stringify(tree))

    }
  }
}

export interface IStateTreeProperty {
  prop: string
  value: any
  persistence?: boolean
}