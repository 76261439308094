// COSTANTI

// Versione applicazione
export const VERSION = '0.0.1';

// URL di base Web Service
export const WS_BASE_URL = 'http://localhost/';

// Timeout richieste POST
export const REQUEST_TIMEOUT = 30000;

// Tentativi richieste POST
export const REQUEST_RETRIES = 3;
