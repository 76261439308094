import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ph-badge',
  templateUrl: './ph-badge.component.html',
  styleUrls: ['./ph-badge.component.scss']
})
export class PhBadgeComponent implements OnInit {

  @Input("text") text: string = ""
  @Input("color") color: string = "primary"

  constructor() { }

  ngOnInit() {
  }

}
