/* ANGULAR IMPORTS */

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/* PAGES */

import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { ShowcaseComponent } from './pages/showcase/showcase.component';

/* CLASSES */

import { AppRoutingModule } from './app.routing';
import { AuthGuard } from 'src/classes/auth-guard';
import { LabelPipe } from 'src/pipes/label.pipe';

/* PLUGINS */

import { ClarityModule } from '@clr/angular';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgDatepickerModule } from 'ng2-datepicker';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material';
import { MatSelectModule } from '@angular/material/select';

/* SERVICES */

import { AlertService } from 'src/services/alert/alert.service';
import { SharedService } from 'src/services/shared/shared.service';

/* DIRECTIVES */

import { ConfirmationDirective } from '../directives/confirmation/confirmation.directive';

/* COMPONENTS */

import { PhInputComponent } from './components/ph-input/ph-input.component';
import { PhInputTooltipComponent } from './components/ph-input-tooltip/ph-input-tooltip.component';
import { PhTooltipComponent } from './components/ph-tooltip/ph-tooltip.component';
import { PhButtonComponent } from './components/ph-button/ph-button.component';
import { PhCheckboxComponent } from './components/ph-checkbox/ph-checkbox.component';
import { PhRadioComponent } from './components/ph-radio/ph-radio.component';
import { PhSelectComponent } from './components/ph-select/ph-select.component';
import { PhSpinnerComponent } from './components/ph-spinner/ph-spinner.component';
import { PhDatepickerComponent } from './components/ph-datepicker/ph-datepicker.component';
import { PhAlertComponent } from './components/ph-alert/ph-alert.component';
import { PhModalConfirmComponent } from './components/ph-modal-confirm/ph-modal-confirm.component';
import { PhBadgeComponent } from './components/ph-badge/ph-badge.component';
import { PhTimelineComponent } from './components/ph-timeline/ph-timeline.component';
import { PhTabsComponent } from './components/ph-tabs/ph-tabs.component';
import { PhTabComponent } from './components/ph-tab/ph-tab.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { StatsComponent } from './pages/stats/stats.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    LabelPipe,
    PhSpinnerComponent,
    PhInputComponent,
    PhInputTooltipComponent,
    PhTooltipComponent,
    ShowcaseComponent,
    PhButtonComponent,
    PhCheckboxComponent,
    PhRadioComponent,
    PhSelectComponent,
    PhDatepickerComponent,
    PhAlertComponent,
    ConfirmationDirective,
    PhModalConfirmComponent,
    PhBadgeComponent,
    PhTimelineComponent,
    PhTabsComponent,
    PhTabComponent,
    SidenavComponent,
    StatsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ClarityModule,
    BrowserAnimationsModule,
    TooltipModule,
    NgSelectModule,
    NgDatepickerModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatSelectModule
  ],
  providers: [
    AuthGuard,
    AlertService,
    SharedService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    library.addIconPacks(far);
  }
}
