export enum InputStatusEnum {
    VALID = "VALID",
    INVALID = "INVALID"
}

export enum ValErrorsEnum {
    REQUIRED = "required"
}

export enum BtnColorEnum {
    SUCCESS = "btn-success",
    INFO = "btn-info",
    WARNING = "btn-warning",
    DANGER = "btn-danger"
}

export enum StatusEnum {
    SUCCESS = 0,
    WARNING = 1,
    ERROR = 2
}