// LABELS
export const Labels = {

    COMMON: {
        SYSTEM: 'SISTEMA',
        WEBSITE_NAME: 'TEST WEBSITE',
        VERSION: '0.0.1',
        FOOTER_TEXT: ' Work in Progress - v'
    },

    LOGIN: {
        USERNAME: 'Username',
        PASSWORD: 'Password',
        TYPE_USERNAME: 'Inserisci username',
        TYPE_PASSWORD: 'Inserisci password',
        DO_LOGIN: 'ACCEDI',
        COPYRIGHT: '© Butch 2020'
    },

    ERROR: {

        UNKNOWN: 'Errore Sconosciuto',

        APPLICATION: {
            GENERIC_ERROR: 'Errore generico del server, contattare un amministratore di sistema',
            BAD_LOGIN: 'Le credenziali inserite sono errate',
            SESSION_EXPIRED: 'La sessione corrente è scaduta',
            INVALID_TOKEN: 'Il tuo stato di autenticazione non è più valido. Effettua nuovamente il Login',
            UNAUTHORIZED: 'Non sei autorizzato a compiere questa operazione'
        },

        HTTP: {
            TIMEOUT_NO_CONNECTION: 'Non è stato possibile raggiungere il server, controlla la tua connessione',
            BAD_REQUEST: 'Errore interno del server, contattare un amministratore di sistema',
            NOT_FOUND: 'Non è stato trovato il servizio, contattare un amministratore di sistema',
            NOT_ALLOWED: 'Questo servizio non ha accettato la richiesta, contattare un amministratore di sistema'
        },

        VALIDATION: {
            REQUIRED: 'Questo campo è obbligatorio',
            UNKNOWN: 'Errore di validazione'
        }
    }
};