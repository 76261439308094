import { HttpClient, HttpHeaders } from '@angular/common/http';
import { REQUEST_TIMEOUT, REQUEST_RETRIES, WS_BASE_URL } from '../../utils/constants';
import { ResponseReader } from '../../classes/response-reader';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/timeout';
import 'rxjs/add/operator/retry';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';

export class HttpSenderService {

  /**
   * Costruttore
   * @param http Servizio richieste HTTP 
   * @param entity Entità
   */
  constructor(private http: HttpClient, private entity: string) {
  }

  /**
   * Richiesta POST
   * @param payload Payload della richiesta
   * @param operation Operazione della richiesta
   */
  protected doPost(payload: any, operation: string): Observable<any> {

    // Costruisci Headers

    let customHeaders = this.buildHeaders()

    // Costruisci URL

    let URL = this.buildURL(operation)

    // Manda richiesta HTTP

    let httpRequest =

      this.http
        .post<any>(URL, JSON.stringify(payload), { headers: customHeaders })
        .timeout(REQUEST_TIMEOUT)
        .retry(REQUEST_RETRIES)
        .map(this.handleResponse.bind(this))
        .catch(this.handleError.bind(this))

    return httpRequest

  }

  /**
   * Richiesta GET
   */
  protected doGet(): Observable<any> {

    // Costruisci Headers

    let customHeaders = this.buildHeaders()

    // Costruisci URL

    let URL = this.buildURL()

    // Manda richiesta HTTP

    let httpRequest = this.http
      .get<any>(URL, { headers: customHeaders })
      .timeout(REQUEST_TIMEOUT)
      .retry(REQUEST_RETRIES)
      .map(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this))

    return httpRequest

  }
  
  /**
   * Richiesta GET (Utilizzata solo per MOCK)
   * @param url Percorso al JSON di Mock
   */
  protected doMock(url: string): Observable<any> {

    let customHeaders = this.buildHeaders()

    let httpRequest = this.http
      .get<any>(url, { headers: customHeaders })
      .map(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this))

    return httpRequest
    
  }

  /**
   * Gestione Risposta
   * @param response Risposta
   */
  private handleResponse(response): ResponseReader {

    // Inserisci la risposta nell'oggetto ResponseReader come Success

    let reader: ResponseReader = new ResponseReader(response, null)

    // Se la Risposta è un Errore, mandala in catch

    if (reader.isError()) {
      throw response
    }

    // Ritorna la risposta di Success

    console.log("Response", reader)

    return reader

  }

  /**
   * Gestione Errore
   * @param error Errore
   */
  private handleError(error): Observable<ResponseReader> {

    let reader: ResponseReader

    if (error.report) {

      // Se l'errore è applicativo:
      // Inserisci l'oggetto di errore come IResponse

      reader = new ResponseReader(error, null)

    } else {

      // Se l'errore è HTTP:
      // Inserisci l'oggetto di errore come IResponseError

      reader = new ResponseReader(null, error)
    }

    // Throwa il Reader

    console.log("Response", reader)

    return Observable.throw(reader)

  }

  /**
   * Costruisci Headers richiesta
   */
  private buildHeaders(): HttpHeaders {

    let headers = new HttpHeaders().set('Content-Type', 'application/json')

    // Crea l'header di autenticazione solo se è presente il token nella session

    let token = sessionStorage.getItem("token")

    if (token) {
      headers.append('token', token)
    }

    // Restituisci Headers

    return headers

  }

  /**
   * Costruisci URL richiesta
   * @param operation Operazione richiesta
   */
  private buildURL(operation: string = ""): string {

    let URL: string = WS_BASE_URL + "/" + this.entity

    URL = operation ? URL + "/" + operation : URL

    return URL

  }
}