import { AlertService } from 'src/services/alert/alert.service';
import { SharedService } from 'src/services/shared/shared.service';
import { Component, OnInit } from '@angular/core';
import { IAlert } from 'src/interfaces/IAlert';

@Component({
  selector: 'ph-alert',
  templateUrl: './ph-alert.component.html',
  styleUrls: ['./ph-alert.component.scss']
})
export class PhAlertComponent implements OnInit {

  alert: IAlert = null
  icon: string = ""

  constructor(private sharedService: SharedService, private alertService: AlertService) {}

  ngOnInit() {

    let that = this

    this.sharedService.currentStateTree.subscribe({

      next: (tree) => {

        that.alert = tree.read<IAlert>("alert")

        if (that.alert) {
          
          switch (that.alert.type) {

            case "warning":
              that.icon = "exclamation-circle"
              break

            case "info":
              that.icon = "info-circle"
              break

            case "danger":
              that.icon = "exclamation-triangle"
              break

            case "success":
              that.icon = "check-circle"
              break
          }
        }

      },
      error: (error) => {

        console.error("Alert error", error)

      }
    })
  }

  onClose() {

    this.alert = null
    this.icon = ""

    this.alertService.destroyAlert()

  }
}